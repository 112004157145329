/* eslint-disable no-duplicate-imports */
import * as React from 'react';
import { INosBusinessSignUpResources } from '../nos-business-sign-up.props.autogenerated';

export interface IBusinessSignUpDropdownProps {
    id?: string;
    value: string;
    className: string;
    isRequired: boolean;
    options: IBusinessSignUpDropdownOption[];
    onChange: (event: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    fieldReference?: React.RefObject<HTMLSelectElement>;
    selectValue?: IBusinessSignUpDropdownOption;
    isSubmittedPressed: boolean;
    resources: INosBusinessSignUpResources;
}

export interface IBusinessSignUpDropdownOption {
    key: string;
    value: string;
    InternationalCallingCode?: string;
}

export const BusinessSignUpDropdown: React.FC<IBusinessSignUpDropdownProps> = ({
    id,
    value,
    className,
    isRequired,
    options,
    onChange,
    fieldReference,
    selectValue,
    isSubmittedPressed,
    resources
}) => {
    return (
        <select
            id={id}
            value={selectValue?.value}
            className={`msc-address-form__dropdown ms-form__dropdown ${isRequired &&
                selectValue?.value === '' &&
                isSubmittedPressed &&
                'necessary'}`}
            aria-required={isRequired}
            onChange={(event: any) => onChange(event)}
        >
            <option selected value={''}>
                {resources.select}
            </option>
            {options.map((option: IBusinessSignUpDropdownOption) => {
                return (
                    <option key={option.key} value={option.value}>
                        {option.value}
                    </option>
                );
            })}
        </select>
    );
};

export default BusinessSignUpDropdown;
