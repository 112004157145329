import * as React from 'react';

interface ISectionHeaderProps {
    header: string;
    sectionLabel?: string;
    number?: string;
}

const SectionHeader: React.FC<ISectionHeaderProps> = ({ header, sectionLabel, number }) => {
    return (
        <React.Fragment>
            <div className='nos-business-sign-up__section-square'>
                <div className={'nos-business-sign-up__section-square-number'}>{number}</div>
                <h3 className='nos-business-sign-up__section-square-heading'>{header}</h3>
            </div>
            <div className='mb-m'>{sectionLabel}</div>
        </React.Fragment>
    );
};

export default SectionHeader;
