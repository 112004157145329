export enum Sizes {
    Single = '1',
    Small = '2 - 9',
    Medium = '10 - 20',
    Big = '21 - 100',
    Giant = '101 - 1000'
}

export const companySizes = [
    { key: Sizes.Single, value: Sizes.Single },
    { key: Sizes.Small, value: Sizes.Small },
    { key: Sizes.Medium, value: Sizes.Medium },
    { key: Sizes.Big, value: Sizes.Big },
    { key: Sizes.Giant, value: Sizes.Giant }
];
